import React, { useEffect, useState } from 'react'
import { authedGet, authedPost } from '../lib/util/http'

interface DateEntry {
    name: string
    value: string
}

export function ExportLoket() {
    const [dates, setDates] = useState([] as DateEntry[])
    const [selectedDate, setSelectedDate] = useState('')
    const [result, setResult] = useState('')
    const [error, setError] = useState('')
    const [log, setLog] = useState([])
    const [processing, setProcessing] = useState(false)

    const getDates = async () => {
        const res = await authedGet('loket/exports/dates')
        const json = await res.json()
        const dates = json.map((yearMonth: string) => ({
            name: yearMonth,
            value: yearMonth,
        }))
        setDates(dates)
        if (dates && dates.length > 0) {
            setSelectedDate(dates[0].value)
        }
    }

    const doExport = async () => {
        if (
            !window.confirm(
                'Are you sure? This will export data into a Loket spreadsheet.'
            )
        ) {
            return
        }
        setResult(
            'Running batch export. This could take some time, please be patient.'
        )
        setError('')
        setProcessing(true)
        const date = `${selectedDate}-01T00:00:00Z`
        const response = await authedPost(`loket/exports?date=${date}`, {})
        if (!response.ok) {
            const err = await response.text()
            setResult('')
            setError(err)
        } else {
            setResult('Loket export succeeded, download triggered')
            setError('')
            const blob = await response.blob()

            const blobUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = blobUrl
            a.download = `loket_${selectedDate}.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(blobUrl)
        }
        setProcessing(false)
    }

    useEffect(() => {
        getDates()
    }, [])

    return (
        <div className="export">
            <h1>Loket Export</h1>
            <div>
                Month to export:&nbsp;&nbsp;
                <select
                    className="dropdown"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                >
                    {dates.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <br />
                <button
                    className="btn"
                    disabled={processing}
                    onClick={doExport}
                >
                    Export to Loket
                </button>
            </div>
        </div>
    )
}
