import React, { useState, useEffect } from 'react'
import './LoginPage.css'
import logo from './COPRA-logo.png'
import {
    loadUserByToken,
    setUserPasswordByToken,
} from '../state/asyncUserActions'
import { AuthenticationState } from '../state/types'
import { urlParamsToMap } from '../lib/util/urls'

interface Props {
    authentication: AuthenticationState
}

export const SetPasswordPage = ({ authentication }: Props) => {
    const [password, setPassword] = useState('')

    const params = urlParamsToMap(window.location.search)
    const token = params && params.token

    useEffect(() => {
        loadUserByToken(token || '')
    }, [token])

    return (
        <div className="wrapper">
            <div className="login-container">
                <div className="login-form">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            setUserPasswordByToken({
                                token,
                                password,
                            })
                        }}
                    >
                        <img
                            src="/Magno_logo.png"
                            alt="Magno logo"
                            className="logo"
                        />
                        <h1>Set password</h1>

                        {!authentication.userByToken && (
                            <h2 className="login-error">
                                Could not find user. Please check the link in
                                your email is correct.
                            </h2>
                        )}
                        {authentication.error && (
                            <h2 className="login-error">
                                {authentication.error}
                            </h2>
                        )}

                        {authentication.userByToken && (
                            <div>
                                <div className="login-welcome">
                                    Welcome to COPRA,{' '}
                                    {authentication.userByToken.name}!<br />
                                    Please set your password, minimum 7
                                    characters.
                                </div>
                                <label>
                                    Password:
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </label>
                                <button type="submit" className="login-button">
                                    Set Password
                                </button>
                                <div className="clear"></div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}
