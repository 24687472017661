import React, { useState } from 'react'
import './LoginPage.css'
import { Login } from '../types/model'
import { loginUser } from '../state/asyncUserActions'
import { AuthenticationState } from '../state/types'
import { navigate } from '../state/navigateActions'
import { Checkbox } from '../lib/ui/Checkbox'

interface Props {
    authentication: AuthenticationState
}

export const LoginPage = ({ authentication }: Props) => {
    const [state, setState] = useState<Login>({
        email: '',
        password: '',
    })

    return (
        <div className="wrapper">
            <div className="login-container">
                <div className="login-form">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            loginUser(state)
                        }}
                    >
                        <img
                            src="/Magno_logo.png"
                            alt="Magno logo"
                            className="logo"
                        />
                        <h1>Log in</h1>

                        {authentication.error && (
                            <h2 className="login-error">
                                {authentication.error}
                            </h2>
                        )}

                        <label>
                            Email
                            <input
                                placeholder="email@example.com"
                                value={state.email}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </label>
                        <label>
                            Password
                            <input
                                type="password"
                                placeholder="Example1!"
                                value={state.password}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        password: e.target.value,
                                    })
                                }
                            />
                        </label>

                        <button type="submit" className="login-button">
                            Login
                        </button>
                        <button
                            type="button"
                            className="forgot-link"
                            onClick={() => navigate('reset-password')}
                        >
                            Reset password
                        </button>
                        <div className="clear"></div>
                    </form>
                </div>
            </div>
        </div>
    )
}
