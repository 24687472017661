import React, { useState } from 'react'

import '../lib/ui/Modal.css'
import './RejectModal.css'

interface Props {
    onCancel: () => void
    onSubmit: (result: string) => void
}

export const RejectModal = (props: Props) => {
    const [remark, setRemark] = useState('')

    return (
        <div>
            <div className="modal-title">Reject</div>
            <div className="modal-section">
                <button
                    className="modal-btn"
                    onClick={() => props.onSubmit(remark)}
                >
                    Reject
                </button>
                <button
                    className="modal-back-btn"
                    onClick={() => props.onCancel()}
                >
                    Cancel
                </button>
            </div>
            <div className="modal-section">
                <p>Your remark will be visible to the submitter.</p>
                <div>
                    <div className="label">Remark</div>
                    <div className="value">
                        <textarea
                            onChange={(e) => setRemark(e.target.value)}
                            value={remark}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
