import React from 'react'

import { ExportExact } from './ExportExact'
import { ExportLoket } from './ExportLoket'

import './Export.css'

export const Export = () => {
    return (
        <div>
            <ExportExact />
            <ExportLoket />
        </div>
    )
}
